.skeleton:empty {
  position: relative;
  background-color: var(--py-color-grey-lighter);
  overflow: hidden;

  &::before {
    content: '\200b'; // https://stackoverflow.com/questions/14217902/how-to-set-empty-span-height-equal-to-default-line-height
  }

  @for $i from 1 through 10 {
    &.skeleton#{$i}::after {
      animation-delay: #{($i - 1) * 50}ms;
    }
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(transparent),
      color-stop(var(--py-color-grey-light)),
      to(transparent)
    );

    background: linear-gradient(90deg, transparent, var(--py-color-grey-light), transparent);
    animation: skeleton 1.1s infinite;
  }

  @keyframes skeleton {
    0% {
      transform: translateX(-120%);
    }
    20% {
      transform: translateX(-80%);
    }
    80% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(120%);
    }
  }
}
